import axios from "axios";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { User } from "../supabase";

export interface Profile extends User {}

export const fetchProfileAtom = loadable(
	atom<Promise<Profile | null>>(async () => {
		const res = await axios.get<Profile>("/api/profile");
		return res.data;
	}),
);
